import ArrowButton from '@/assets/ArrowButton';
import ArrowGray from '@/assets/ArrowGray';
import CalendarGray from '@/assets/CalendarGray';
import ClockGray from '@/assets/ClockGray';
import React from 'react';

interface ArticleCardProps {
  image: string;
  date: string;
  readTime?: string;
  title: string;
  authorName: string;
  authorLocation: string;
  authorAvatar: string;
  id?: number;
}

const ArticleCard: React.FC<ArticleCardProps> = ({ image, date, readTime, title, authorName, authorLocation, authorAvatar, id }) => {
  return (
    <div className="bg-primary rounded-[25px] p-0 h-full flex flex-col">
        <div>
          <img src={image} alt={title} className="w-full rounded-t-[25px]"/>
        </div>
        <div className="p-4 flex-1 flex flex-col">
          <div className="flex items-center space-x-8 text-base-100 text-sm mb-2">
            <div className='flex items-center space-x-1'>
              <CalendarGray />
              <span className='font-[300] text-[14px] leading-[19px] text-base-100'>{date}</span>
            </div>
          </div>
          <h3 className="font-[700] text-[25px] leading-[34px] text-base-100 mb-2">{title}</h3>
          <div className="mt-auto">
            <div className="flex items-center justify-between">
              <div className='flex items-center'>
                <img src={authorAvatar} alt={`${authorName}'s avatar`} className="w-14 h-14 rounded-full mr-4" />
                <div>
                  <p className="font-[700] text-[16px] leading-[21px] text-base-100 mb-0">{authorName}</p>
                  <p className="font-[300] text-[14px] leading-[35px] text-neutral mt-0">{authorLocation}</p>
                </div>
              </div>
              <div>
                <ArrowGray />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ArticleCard;
