import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/yellow.css"
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { useVignetteContext } from "@/context/VignetteContext";
import { useEffect, useState } from "react";

interface CustomDatePickerProps 
{
    value: string,
    onChange: any;
    isStart: boolean;
    country?: string | null;
    vTypeYear?: boolean;
    noBase?: boolean;
}

interface CustomInputProps {
    onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    value: string;
  }

const formatDate = (date: any) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};


const CustomDatePicker: React.FC<CustomDatePickerProps> = ({value, onChange, isStart, country, vTypeYear, noBase = false}) => {
    const {startDate, setStartDate, endDate, setEndDate} = useVignetteContext();
    const weekDays = ["N", "Pon", "Wt", "Śr", "Czw", "Pt", "Sob"]
    const months = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]

    if(!noBase)
    {
        useEffect(() => {
            if(startDate.split("T")[0]<new Date().toISOString().split("T")[0])
            {
                setStartDate(new Date().toISOString())
            }
        }, [startDate, endDate]);
    }

    const getMaxDateForStart = () => {
        const today = new Date();
        let maxDate = null;

        switch (country) {
            case "Rumunia":
            case "Czechy":
                maxDate = new Date(today.setDate(today.getDate() + 31)).toISOString();
                break;
            case "Węgry":
                if (vTypeYear == true) {
                    maxDate = new Date(today.getFullYear() + 1, 0, 31).toISOString();
                    setEndDate(new Date(today.getFullYear() + 1, 0, 31).toISOString());
                } else {
                    maxDate = new Date(today.getFullYear(), 11, 31+1).toISOString();
                }
                break;
            case "Austria":
                if (vTypeYear == true) {
                    maxDate = new Date(today.getFullYear() + 1, 0, 31).toISOString();
                    setEndDate(new Date(today.getFullYear() + 1, 11, 31).toISOString());
                    setStartDate(new Date(today.getFullYear() + 1, 0, 1).toISOString())
                } else {
                    maxDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()+1).toISOString();
                }
                break;
            default:
                maxDate = '';
        }

        return maxDate;
    };

    const CustomStartInput: React.FC<CustomInputProps> = ({ onFocus, value }) => {
        return (
            <input
                onFocus={onFocus}
                value={value}
                type="text"
                placeholder={formatDate(new Date(startDate))}
                readOnly
                className="bg-transparent dat text-neutral border-none w-3/5 focus:outline-none cursor-default"
            />
        )
    }
    
    const CustomEndInput: React.FC<CustomInputProps> = ({ onFocus, value }) => {
        return (
            <input
                onFocus={onFocus}
                value={value}
                type="text"
                placeholder={formatDate(new Date(endDate))}
                readOnly
                className="bg-transparent dat text-neutral border-none w-3/5 focus:outline-none cursor-default"
            />
        )
    }

    const handleStart = (value: any) => {
        if(endDate.split("T")[0]<startDate.split("T")[0])
        {
            if(startDate.split("T")[0]<new Date().toISOString().split("T")[0])
            {
                setStartDate(new Date().toISOString())
            }
            let tmp = endDate;
            setEndDate(startDate);
            setStartDate(tmp);
        }
        value.hour = 1;
        setStartDate(new Date(value).toISOString());

        if(startDate.split("T")[0]<new Date().toISOString().split("T")[0])
        {
            setStartDate(new Date().toISOString())
            setEndDate(new Date().toISOString())
        }
    };

    const handleEnd = (value: any) => {
        if(endDate.split("T")[0]<startDate.split("T")[0])
        {
            let tmp = endDate;
            setEndDate(startDate);
            setStartDate(tmp);
        }
        value.hour = 1;
        setEndDate(new Date(value).toISOString());

        if(endDate.split("T")[0]<new Date().toISOString().split("T")[0])
        {
            setStartDate(new Date().toISOString())
            setEndDate(new Date().toISOString())
        }

        //console.log(new Date(value).toISOString())

        // if(new Date(endDate).toISOString() <= new Date().toISOString())
        // {
        //     setEndDate(new Date().toISOString());
        // }

        // if(new Date(startDate).toISOString() <= new Date().toISOString())
        // {
        //     setStartDate(new Date().toISOString());
        // }
    };
    

    return (
        <div>{
            isStart ? (
                <DatePicker
                    format="DD.MM.YYYY"
                    required={true}
                    weekDays={weekDays}
                    months={months}
                    value={value}
                    weekStartDayIndex={1}
                    onChange={handleStart}
                    minDate={new Date(new Date().setDate(new Date().getDate()-1)).toISOString()}
                    maxDate={getMaxDateForStart()}
                    className='yellow bg-dark'
                    inputClass='bg-transparent border-0 text-white'
                    render={<CustomStartInput onFocus={onChange} value={value}/>}
                />
            ) 
            : 
            (
                <DatePicker
                    format="DD.MM.YYYY"
                    required={true}
                    weekDays={weekDays}
                    months={months}
                    weekStartDayIndex={1}
                    value={value}
                    onChange={handleEnd}
                    minDate={startDate !== '' ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() - 1)).toISOString() : new Date(new Date().setDate(new Date().getDate() - 1)).toISOString()}
                    maxDate={
                        startDate !== '' 
                            ? new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 364)).toISOString() 
                            : new Date(new Date().setDate(new Date().getDate() + 364)).toISOString()
                    }
                    className='yellow bg-dark'
                    inputClass='bg-transparent border-0 text-white'
                    render={<CustomEndInput onFocus={onChange} value={value}/>}
                />
            )
        }</div>
    )
}



export default CustomDatePicker;