import React, { useEffect, useState } from 'react';
import AustriaIcon from '../assets/austria.png';
import CzechIcon from '../assets/czechy.png';
import PolskaIcon from '../assets/poland.png';
import AustriaImg from '../assets/austriaImage.png';
import CzechImg from '../assets/czechImage.png';
import ArrowButton from '@/assets/ArrowButton';
import YellowLine from './YellowLine';
import VignetteCard from './VignetteCard';
import vignetteCardImg from '../assets/vignetteCard.png'; 
import axios from "@/axiosConfig";
import SzwajcariaIcon from "../assets/szwajcaria.png";
import WegryIcon from "../assets/wegry.png";
import RumuniaIcon from "../assets/rumunia.png";
import slovakia from '../assets/Slovakia.png';
import bulgaria from '../assets/bulgaria.png';
import slovenia from '../assets/slovenia.png';
import { useTranslation } from 'react-i18next';

const vignetteData = [
  {
    imageUrl: vignetteCardImg.src,
    vignetteTitle: 'E-winieta na 10 dni',
    startDate: '07.11.2024',
    endDate: '07.11.2024',
    countryFrom: PolskaIcon.src,
    countryFromLabel: 'Polska',
    countryTo: CzechIcon.src,
    countryToLabel: 'Czechy',
    pricePLN: 64.99,
    priceCurrency: 'CZK',
    currencyValue: 270.0,
  },
  {
    imageUrl: vignetteCardImg.src,
    vignetteTitle: 'E-winieta na 10 dni',
    startDate: '07.11.2024',
    endDate: '07.11.2024',
    countryFrom: PolskaIcon.src,
    countryFromLabel: 'Polska',
    countryTo: CzechIcon.src,
    countryToLabel: 'Czechy',
    pricePLN: 64.99,
    priceCurrency: 'CZK',
    currencyValue: 270.0,
  },
  {
    imageUrl: vignetteCardImg.src,
    vignetteTitle: 'E-winieta na 10 dni',
    startDate: '07.11.2024',
    endDate: '07.11.2024',
    countryFrom: PolskaIcon.src,
    countryFromLabel: 'Polska',
    countryTo: CzechIcon.src,
    countryToLabel: 'Czechy',
    pricePLN: 64.99,
    priceCurrency: 'CZK',
    currencyValue: 270.0,
  },
  {
    imageUrl: vignetteCardImg.src,
    vignetteTitle: 'E-winieta na 10 dni',
    startDate: '07.11.2024',
    endDate: '07.11.2024',
    countryFrom: PolskaIcon.src,
    countryFromLabel: 'Polska',
    countryTo: CzechIcon.src,
    countryToLabel: 'Czechy',
    pricePLN: 64.99,
    priceCurrency: 'CZK',
    currencyValue: 270.0,
  },
];

type VignetteApiData = {
  startDate: string;
  endDate: string;
  price: number;
  period: number;
  countryName: string;
};

type VignetteCardData = {
  imageUrl: string;
  vignetteTitle: string;
  startDate: string;
  endDate: string;
  countryFrom: string;
  countryFromLabel: string;
  countryTo: string;
  countryToLabel: string;
  pricePLN: number;
  priceCurrency: string;
  currencyValue: number;
  currency: string;
  basePrice: string;
};

const HistorySection: React.FC = () => {
  const [data, setData] = useState<VignetteCardData[]>([]);
  const { t } = useTranslation('common');



  const fetchRecentVignettes = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`/Vignette/recent`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        const transformedData = response.data.data.map((item: any) => {
          
          let iconUrl = "";

          switch (item.countryName)
          {
            case "Austria":
              iconUrl = AustriaIcon.src
            break;
            case "Czechy":
              iconUrl = CzechIcon.src
            break;
            case "Szwajcaria":
              iconUrl = SzwajcariaIcon.src
            break;
            case "Węgry": 
              iconUrl = WegryIcon.src
            break;
            case "Rumunia":
              iconUrl = RumuniaIcon.src 
            break;
            case "Słowacja":
              iconUrl = slovakia.src 
            break;
            case "Bułgaria":
              iconUrl = bulgaria.src 
            break;
            case "Słowenia":
              iconUrl = slovenia.src 
            break;
            default:
              iconUrl = PolskaIcon.src 
          }
          
          return {
            imageUrl: vignetteCardImg.src,
            vignetteTitle: `Winieta na ${item.period} ${item.period == 1 ? 'dzień' : 'dni'}`,
            startDate: new Date(item.startDate).toLocaleDateString(),
            endDate: new Date(item.endDate).toLocaleDateString(),
            countryFrom: PolskaIcon.src,
            countryFromLabel: "Polska",
            countryTo: iconUrl,
            countryToLabel: item.countryName,
            pricePLN: item.price,
            priceCurrency: "PLN",
            currencyValue: item.price,
            currency: item.currency,
            basePrice: item.basePrice
          }
        });
        setData(transformedData);
      } else {
        console.error("Błąd podczas pobierania danych:", response.data.message);
      }
    } catch (error) {
      console.error("Błąd podczas pobierania winiet:", error);
    }
  };

  useEffect(() => {
    fetchRecentVignettes();
  }, []);

  return (
    <section className="py-12 lg:max-w-[1200px] p-6 lg:p-0 mx-auto">
      <div className="lg:flex lg:justify-between lg:items-center mb-6 lg:space-x-12 space-y-10 lg:space-y-0">
        <div className="flex flex-col">
            <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-3 mt-0">{t('historySection.header1')}<br/>{t('historySection.header2')}</h2>
            <YellowLine/>
        </div>
        <div className="lg:w-1/2">
          <p className="text-base-100 text-[16px] leading-[35px]">{t('historySection.text1')}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-12">
      {data.length > 0 ? (
          data.map((vignette, index) => (
            <VignetteCard
              key={index}
              imageUrl={vignette.imageUrl}
              vignetteTitle={vignette.vignetteTitle}
              startDate={vignette.startDate}
              endDate={vignette.endDate}
              countryFrom={vignette.countryFrom}
              countryFromLabel={vignette.countryFromLabel}
              countryTo={vignette.countryTo}
              countryToLabel={vignette.countryToLabel}
              pricePLN={vignette.pricePLN}
              priceCurrency={vignette.priceCurrency}
              currencyValue={vignette.currencyValue}
              currency={vignette.currency}
              basePrice={vignette.basePrice}
              onOrder={() => {}}
            />
          ))
        ) : (
          <p className="text-base-100">Brak ostatnich zakupionych winiet.</p>
        )}
      </div>
    </section>
  );
};

export default HistorySection;
