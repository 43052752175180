const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    fill="none"
    viewBox="0 0 52 52"
  >
    <circle cx="26" cy="26" r="25" stroke="#242226" strokeWidth="2"></circle>
    <path
      fill="#FED017"
      d="M21.646 18.584v-2.75c0-.376.311-.688.687-.688s.688.312.688.688v2.75a.69.69 0 0 1-.688.687.693.693 0 0 1-.687-.687M28.98 18.584v-2.75c0-.376.31-.688.687-.688.375 0 .687.312.687.688v2.75a.693.693 0 0 1-.687.687.69.69 0 0 1-.688-.687M22.443 27.218a1 1 0 0 1-.302-.192.95.95 0 0 1-.266-.651c0-.12.027-.238.073-.348q.069-.165.193-.303a1 1 0 0 1 .302-.192.94.94 0 0 1 1 .192.96.96 0 0 1 .265.651c0 .055-.009.12-.018.183a.6.6 0 0 1-.055.165.7.7 0 0 1-.083.165c-.027.046-.073.092-.11.138a.96.96 0 0 1-.65.266.9.9 0 0 1-.349-.074M25.652 27.218a1 1 0 0 1-.303-.192.95.95 0 0 1-.266-.651c0-.12.028-.239.074-.349q.069-.165.192-.302a1 1 0 0 1 .303-.193.92.92 0 0 1 .999.193.96.96 0 0 1 .266.65c0 .056-.01.12-.019.184a.6.6 0 0 1-.055.165.7.7 0 0 1-.082.165c-.028.046-.073.092-.11.137a.96.96 0 0 1-.651.266.9.9 0 0 1-.348-.073M28.86 27.218a1 1 0 0 1-.302-.192l-.11-.138a.7.7 0 0 1-.083-.165.6.6 0 0 1-.055-.165 1.4 1.4 0 0 1-.018-.183c0-.239.1-.477.265-.651a1 1 0 0 1 .303-.193.92.92 0 0 1 1 .193.96.96 0 0 1 .265.65c0 .056-.01.12-.018.184a.6.6 0 0 1-.055.165.7.7 0 0 1-.083.165c-.027.046-.073.092-.11.137a.96.96 0 0 1-.65.266.9.9 0 0 1-.349-.073M22.443 30.427a1 1 0 0 1-.302-.193.96.96 0 0 1-.266-.65c0-.12.027-.239.073-.349q.069-.18.193-.302c.339-.34.962-.34 1.302 0a.96.96 0 0 1 .265.65c0 .239-.1.477-.265.651a.96.96 0 0 1-.651.266.9.9 0 0 1-.349-.073M25.35 30.234a.96.96 0 0 1-.267-.65c0-.12.028-.239.074-.349q.069-.18.192-.302c.34-.34.963-.34 1.302 0a.9.9 0 0 1 .192.302c.046.11.074.23.074.348 0 .239-.101.477-.266.651A.96.96 0 0 1 26 30.5a.96.96 0 0 1-.65-.266M28.558 30.234a.9.9 0 0 1-.193-.302.9.9 0 0 1-.073-.348q.002-.182.073-.349.069-.179.193-.302a.915.915 0 0 1 .999-.193q.083.028.165.083c.045.027.091.073.137.11a.96.96 0 0 1 .266.65c0 .239-.1.477-.266.651a.96.96 0 0 1-.65.266.96.96 0 0 1-.651-.266M18.208 23.02a.69.69 0 0 1-.687-.687c0-.376.311-.688.687-.688h15.584c.375 0 .687.312.687.688a.69.69 0 0 1-.687.687z"
    ></path>
    <path
      fill="#FED017"
      fillRule="evenodd"
      d="M22.333 34.854c-3.345 0-5.27-1.925-5.27-5.27v-7.792c0-3.346 1.925-5.271 5.27-5.271h7.334c3.346 0 5.27 1.925 5.27 5.27v7.793c0 3.345-1.924 5.27-5.27 5.27zm-3.895-13.062v7.791c0 2.622 1.274 3.896 3.895 3.896h7.334c2.621 0 3.895-1.274 3.895-3.895v-7.792c0-2.622-1.274-3.896-3.895-3.896h-7.334c-2.621 0-3.895 1.274-3.895 3.896"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
