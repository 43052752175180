import * as React from "react";

const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#838086"
      d="M4.833 3.333v-2c0-.273.227-.5.5-.5.274 0 .5.227.5.5v2c0 .274-.226.5-.5.5a.504.504 0 0 1-.5-.5M10.167 3.333v-2c0-.273.226-.5.5-.5.273 0 .5.227.5.5v2c0 .274-.227.5-.5.5a.504.504 0 0 1-.5-.5M5.413 9.613a.7.7 0 0 1-.22-.14A.7.7 0 0 1 5 9q.002-.132.053-.253a.8.8 0 0 1 .14-.22.7.7 0 0 1 .22-.14.68.68 0 0 1 .727.14c.12.126.193.3.193.473 0 .04-.006.087-.013.133a.4.4 0 0 1-.04.12.5.5 0 0 1-.06.12c-.02.034-.053.067-.08.1a.7.7 0 0 1-.473.194.7.7 0 0 1-.254-.054M7.747 9.613a.7.7 0 0 1-.22-.14A.7.7 0 0 1 7.333 9q.001-.132.054-.253a.8.8 0 0 1 .14-.22.7.7 0 0 1 .22-.14.67.67 0 0 1 .726.14c.12.126.194.3.194.473 0 .04-.007.087-.014.133a.4.4 0 0 1-.04.12.5.5 0 0 1-.06.12c-.02.034-.053.067-.08.1A.7.7 0 0 1 8 9.667a.7.7 0 0 1-.253-.054M10.08 9.613a.7.7 0 0 1-.22-.14l-.08-.1a.5.5 0 0 1-.06-.12.4.4 0 0 1-.04-.12A1 1 0 0 1 9.667 9c0-.173.073-.347.193-.473a.7.7 0 0 1 .22-.14.67.67 0 0 1 .727.14c.12.126.193.3.193.473 0 .04-.007.087-.013.133a.4.4 0 0 1-.04.12.5.5 0 0 1-.06.12c-.02.034-.054.067-.08.1a.7.7 0 0 1-.474.194.7.7 0 0 1-.253-.054M5.413 11.947a.8.8 0 0 1-.22-.14.7.7 0 0 1-.193-.474c0-.086.02-.173.053-.253a.6.6 0 0 1 .14-.22.7.7 0 0 1 .947 0 .7.7 0 0 1 .193.473.7.7 0 0 1-.193.474.7.7 0 0 1-.473.193.7.7 0 0 1-.254-.053M7.527 11.807a.7.7 0 0 1-.194-.474c0-.086.02-.173.054-.253a.6.6 0 0 1 .14-.22.7.7 0 0 1 .946 0q.09.09.14.22.053.122.054.253a.7.7 0 0 1-.194.474A.7.7 0 0 1 8 12a.7.7 0 0 1-.473-.193M9.86 11.807a.6.6 0 0 1-.14-.22.7.7 0 0 1-.053-.254c0-.086.02-.173.053-.253a.6.6 0 0 1 .14-.22.67.67 0 0 1 .727-.14q.06.02.12.06c.033.02.066.053.1.08.12.127.193.3.193.473a.7.7 0 0 1-.193.474.7.7 0 0 1-.474.193.7.7 0 0 1-.473-.193M2.333 6.56a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h11.334c.273 0 .5.227.5.5s-.227.5-.5.5z"
    ></path>
    <path
      fill="#838086"
      fillRule="evenodd"
      d="M5.333 15.167c-2.433 0-3.833-1.4-3.833-3.834V5.667c0-2.434 1.4-3.834 3.833-3.834h5.334c2.433 0 3.833 1.4 3.833 3.834v5.666c0 2.434-1.4 3.834-3.833 3.834zM2.5 5.667v5.666c0 1.907.927 2.834 2.833 2.834h5.334c1.906 0 2.833-.927 2.833-2.834V5.667c0-1.907-.927-2.834-2.833-2.834H5.333c-1.906 0-2.833.927-2.833 2.834"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
