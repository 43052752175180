import React from 'react';

interface OpinionCardProps {
  name: string;
  location: string;
  rating: number;
  review: string;
  avatar: string;
}

const OpinionCard: React.FC<OpinionCardProps> = ({ name, location, rating, review, avatar }) => {
  return (
    <div className="bg-secondary box-border rounded-[25px] p-5 max-w-[543px] min-h-[400px] text-left">
      <div className="flex items-center mb-4 border-b-[2px] border-0 border-solid border-base-100">
        <img src={avatar} alt={`${name}'s avatar`} className="w-16 h-16 rounded-full mr-4" />
        <div className='w-full'>
          <h3 className="text-[25px] font-[700] leading-[34px] text-base-100 mb-0">{name}</h3>
          <div className='flex justify-between items-center'>
                <p className="text-[16px] font-[400] leading-[34px] text-neutral mt-1">{location}</p>
            <div className="flex items-center mb-4 space-x-2">
                {[...Array(rating)].map((_, i) => (
                <svg width="16" height="16" viewBox="0 0 16 16" key={i} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.7995 3.96875C10.9895 4.35875 11.4995 4.72875 11.9295 4.80875L14.4795 5.22875C16.1095 5.49875 16.4895 6.67875 15.3195 7.85875L13.3295 9.84875C12.9995 10.1788 12.8095 10.8288 12.9195 11.2988L13.4895 13.7588C13.9395 15.6988 12.8995 16.4588 11.1895 15.4388L8.79945 14.0188C8.36945 13.7588 7.64945 13.7588 7.21945 14.0188L4.82945 15.4388C3.11945 16.4488 2.07945 15.6988 2.52945 13.7588L3.09945 11.2988C3.18945 10.8188 2.99945 10.1688 2.66945 9.83875L0.679454 7.84875C-0.490546 6.67875 -0.110546 5.49875 1.51945 5.21875L4.06945 4.79875C4.49945 4.72875 5.00945 4.34875 5.19945 3.95875L6.60945 1.13875C7.37945 -0.381247 8.61945 -0.381247 9.38945 1.14875L10.7995 3.96875Z" fill="#FEFA17"/>
                </svg>
                ))}
            </div>
          </div>
        </div>
      </div>
      <p className="text-base-100 font-[400] text-[16px] leading-[35px]">"{review}"</p>
    </div>
  );
};

export default OpinionCard;
