const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="51"
    fill="none"
    viewBox="0 0 51 51"
    className="cursor-pointer"
  >
    <rect
      width="49"
      height="49"
      x="1"
      y="1"
      stroke="#171717"
      strokeWidth="2"
      rx="24.5"
    ></rect>
    <path
      stroke="#FED017"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M27.228 20.436 32.792 26l-5.564 5.564M17.208 26h15.428"
    ></path>
  </svg>
);

export default SvgIcon;
