import React, { useEffect, useState } from 'react';
import AustriaIcon from '../assets/austria.png';
import CzechIcon from '../assets/czechy.png';
import SzwajcariaIcon from "../assets/szwajcaria.png";
import WegryIcon from "../assets/wegry.png";
import RumuniaIcon from "../assets/rumunia.png";
import AustriaImg from '../assets/austriaImage.png';
import CzechImg from '../assets/czechImage.png';
import ArrowButton from '@/assets/ArrowButton';
import YellowLine from './YellowLine';
import SzwajcariaImg from "../assets/szwajcariaImage.png";
import WegryImg from "../assets/wegryImage.png";
import RumuniaImg from "../assets/rumuniaImage.png";
import slovakia from '../assets/Slovakia.png';
import SlovakiaImng from '../assets/SlovakiaImg.png';
import bulgaria from '../assets/bulgaria.png';
import BulgariaImng from '../assets/bulgariaImg.png';
import slovenia from '../assets/slovenia.png';
import SloveniaImng from '../assets/sloveniaImg.png';
import { useTranslation } from 'react-i18next';

interface Country {
  name: string;
  flagUrl: string;
  imageUrl: string;
}

const CountrySection: React.FC = () => {
  let countries: Country[] = [];
  const [isReady, setIsReady] = useState<boolean>(false);
  const { t, i18n } = useTranslation('common');
  useEffect(()=>{
    setIsReady(true);

  }, [i18n.isInitialized]);

  if (!i18n.isInitialized) {
    return null; // Możesz wyświetlić spinner lub placeholder
  }

if(isReady)
{
  countries = [
    { name: t('menu.austria'), flagUrl: AustriaIcon.src, imageUrl: AustriaImg.src },
    { name: t('menu.czechy'), flagUrl: CzechIcon.src, imageUrl: CzechImg.src },
    { name: t('menu.szwajcaria'), flagUrl: SzwajcariaIcon.src, imageUrl: SzwajcariaImg.src },
    { name: t('menu.wegry'), flagUrl: WegryIcon.src, imageUrl: WegryImg.src },
    { name: t('menu.rumunia'), flagUrl: RumuniaIcon.src, imageUrl: RumuniaImg.src },
    { name: t('menu.slowacja'), flagUrl: slovakia.src, imageUrl: SlovakiaImng.src },
    { name: t('menu.bulgaria'), flagUrl: bulgaria.src, imageUrl: BulgariaImng.src },
    { name: t('menu.slowenia'), flagUrl: slovenia.src, imageUrl: SloveniaImng.src },
  ];

}


  return (
    isReady ?
    (
      <>
        <section className="py-12 lg:max-w-[1200px] p-6 lg:p-0 mx-auto" id='popular'>
          <div className="lg:flex lg:justify-between lg:items-center mb-6 lg:space-x-12 space-y-10 lg:space-y-0">
            <div className="flex flex-col">
                <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-3">{t('countrySection.header1')}</h2>
                <YellowLine/>
            </div>
            <div className="lg:w-1/2">
              <p className="text-base-100 text-[16px] leading-[35px]">{t('countrySection.text1')}</p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6 mt-12">
            {countries.map((country, index) => (
              <div key={index} className="bg-transparent rounded-[35px] border-2 border-solid border-[#131313] flex items-center hover:bg-primary transition">
                <img src={country.imageUrl} alt={country.name} className="w-40 mr-6 h-full" />
                <div className="flex-1">
                  <div className="flex items-center">
                    <img src={country.flagUrl} alt={`${country.name} Flag`} className="w-8 h-8" />
                  </div>
                  <div className="flex items-center">
                    <h3 className="text-[25px] font-[700] leading-[34px] text-base-100 mb-4 mt-2">{country.name}</h3>
                  </div>
                  <div className="flex justify-between pr-4">
                    <a href="/order" className='no-underline'><p className="text-base-100  text-[16px] font-[700] leading-[21px] cursor-pointer">{t('countrySection.kup')}</p></a>
                    <a href="/order" className='no-underline'><ArrowButton/></a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    )
    :
    null
  );
};

export default CountrySection;
