import CalendarGray from '@/assets/CalendarGray';
import React from 'react';
import AccentButton from './AccentButton';
import router from 'next/router';

interface VignetteCardProps {
  imageUrl: string;
  vignetteTitle: string;
  startDate: string;
  endDate: string;
  countryFrom: string;
  countryFromLabel: string
  countryTo: string;
  countryToLabel: string;
  pricePLN: number;
  priceCurrency: string;
  currencyValue: number;
  currency: string;
  basePrice: string;
  onOrder: () => void;
}

const Arrow = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="14" cy="14" r="13" fill="#171717" stroke="#242226" strokeWidth="2"/>
<path d="M15.4175 10.4591L18.9583 13.9999L15.4175 17.5408" stroke="#838086" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.0415 14H18.859" stroke="#838086" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

const VignetteCard: React.FC<VignetteCardProps> = ({
  imageUrl,
  vignetteTitle,
  startDate,
  endDate,
  countryFrom,
  countryFromLabel,
  countryTo,
  countryToLabel,
  pricePLN,
  priceCurrency,
  currencyValue,
  currency,
  basePrice,
  onOrder,
}) => {
  return (
    <div className="flex bg-secondary text-base-100 border-2 border-solid border-[#131313]  rounded-3xl overflow-hidden">
      {/* Left Image Section */}
      <div className="w-2/5 hidden sm:block">
        <img
          src={imageUrl}
          alt={vignetteTitle}
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Content Section */}
      <div className="w-full sm:w-3/5 p-4 flex flex-col justify-between">
        <div>
          {/* Title */}
          <h2 className="text-lg font-bold mb-2">{vignetteTitle}</h2>

          {/* Date and Country Section */}
          <div className="flex items-center justify-between text-sm mb-2">
            <div className="flex items-center gap-2">
              <CalendarGray />
              <span className='text-tiny text-base-200'>{startDate}</span>
            </div>
            <div className="flex items-center gap-2">
            <CalendarGray />
              <span className='text-tiny text-base-200'>{endDate}</span>
            </div>
          </div>

          <div className="flex items-center justify-between text-sm border-b-2 pb-5 border-solid border-0 border-[#242226]">
            <div className="flex items-center gap-2">
              <img
                src={countryFrom}
                alt={countryFromLabel}
                className="w-5 h-5 rounded-full"
              />
              <span>{countryFromLabel}</span>
            </div>
            <div>
              <Arrow />
            </div>
            <div className="flex items-center gap-2">
              <img
                src={countryTo}
                alt={countryToLabel}
                className="w-5 h-5 rounded-full"
              />
              <span>{countryToLabel}</span>
            </div>
          </div>
        </div>

        {/* Price and Order Button */}
        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-lg mb-0 font-bold text-accent">{pricePLN.toFixed(2)} {priceCurrency} </p>
             <p className="text-sm mt-2">/ {basePrice} {currency}</p>
           </div>
          <AccentButton text='Zamawiam' withArrow={false} classes='font-[500]' onClick={()=>router.push('/order') }/>
        </div>
      </div>
    </div>
  );
};

export default VignetteCard;
