const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    fill="none"
    viewBox="0 0 22 22"
  >
    <path
      fill="#838086"
      fillRule="evenodd"
      d="M12.742 20.13c-1.1-.467-2.21-1.109-3.291-1.888a29 29 0 0 1-3.09-2.631 27.4 27.4 0 0 1-2.63-3.08c-.788-1.1-1.421-2.2-1.87-3.263-.477-1.137-.715-2.237-.715-3.273 0-.715.128-1.393.376-2.026.256-.65.669-1.255 1.228-1.778.706-.697 1.513-1.045 2.374-1.045.358 0 .724.083 1.036.23.358.164.66.412.88.742l2.127 2.997c.192.266.339.523.44.78.119.274.183.55.183.815 0 .349-.1.688-.293 1.008a3.7 3.7 0 0 1-.614.78l-.624.65c.01.028.019.046.028.065.11.192.33.522.751 1.017.45.513.871.981 1.293 1.412.54.532.99.953 1.412 1.302.522.44.861.66 1.063.76l-.018.046.669-.66a3.4 3.4 0 0 1 .825-.632c.504-.312 1.146-.367 1.787-.101.239.1.495.238.77.43l3.044 2.164q.51.347.733.87c.137.35.201.67.201.99 0 .44-.1.88-.293 1.293a4.3 4.3 0 0 1-.733 1.1c-.523.578-1.091.99-1.751 1.256a5.4 5.4 0 0 1-2.044.394c-1.036 0-2.127-.247-3.254-.724M3.703 3.181a3.5 3.5 0 0 0-.898 1.292 4 4 0 0 0-.284 1.522c0 .853.202 1.779.605 2.732.412.971.99 1.98 1.723 2.988a25.4 25.4 0 0 0 2.484 2.915 26 26 0 0 0 2.925 2.493c.99.725 2.007 1.311 3.015 1.733 1.568.67 3.035.825 4.244.32.468-.192.88-.485 1.256-.907.211-.229.376-.476.514-.77.11-.229.165-.467.165-.705 0-.147-.028-.294-.101-.459a.7.7 0 0 0-.257-.275l-3.043-2.163a2.6 2.6 0 0 0-.504-.284c-.202-.083-.285-.165-.596.027-.184.092-.349.23-.532.413l-.697.687c-.357.348-.907.431-1.329.275l-.247-.11c-.376-.201-.816-.513-1.302-.926-.44-.375-.916-.815-1.494-1.384a32 32 0 0 1-1.366-1.485q-.645-.756-.935-1.292l-.11-.275a1.7 1.7 0 0 1-.073-.459c0-.33.119-.623.348-.852l.688-.715q.274-.278.412-.514c.074-.119.101-.22.101-.311a.9.9 0 0 0-.073-.293 2.6 2.6 0 0 0-.294-.486L5.922 2.906a.85.85 0 0 0-.34-.284 1.15 1.15 0 0 0-.458-.101c-.504 0-.971.22-1.42.66m8.938 11.202.247-.642c-.046-.01-.082 0-.1.018z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
