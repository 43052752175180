const MobileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="63"
    fill="none"
    viewBox="0 0 63 63"
  >
    <circle cx="31.5" cy="31.5" r="31.5" fill="#F7F7F7"></circle>
    <g fill="#000" clipPath="url(#clip0_139_1807)">
      <path d="M25.646 21.891a.51.51 0 0 0-.718 0 .51.51 0 0 0 .359.867.51.51 0 0 0 .507-.508.5.5 0 0 0-.148-.359"></path>
      <path d="M40.42 28.17c-.23 0-.453.038-.66.108V21.54c0-1.4-1.14-2.539-2.54-2.539H25.44c-1.4 0-2.54 1.139-2.54 2.54v7.132l-1.068-.328a1.79 1.79 0 0 0-1.602.273 1.79 1.79 0 0 0-.732 1.451c0 .536.231 1.024.614 1.358a1.79 1.79 0 0 0-.614 1.355c0 .535.231 1.023.614 1.357a1.79 1.79 0 0 0-.614 1.355c0 .535.231 1.023.614 1.357a1.79 1.79 0 0 0-.614 1.355c0 .798.512 1.49 1.274 1.725l2.128.654v1.877c0 1.4 1.14 2.539 2.54 2.539h9.8c.28 0 .507-.227.507-.508v-3.406a6.79 6.79 0 0 1 2.924-5.58l.464-.321a.51.51 0 0 0 .218-.418v-4.515a1.067 1.067 0 0 1 2.133 0v8.207c0 .496-.1.979-.296 1.435l-1.896 4.397a.508.508 0 1 0 .932.402l1.897-4.397c.251-.584.379-1.202.379-1.837v-8.207a2.085 2.085 0 0 0-2.082-2.082m-19.586 1.264a.78.78 0 0 1 .7-.12l2.356.724a.78.78 0 0 1 .557.754.78.78 0 0 1-.32.634.78.78 0 0 1-.7.12l-2.356-.724a.785.785 0 0 1-.557-.754c0-.252.116-.484.32-.634m0 2.713a.78.78 0 0 1 .7-.12l2.356.724a.785.785 0 0 1 .557.754.78.78 0 0 1-.32.634.78.78 0 0 1-.7.12l-.48-.148-1.876-.577a.78.78 0 0 1-.557-.753.78.78 0 0 1 .32-.634m3.613 4.07a.78.78 0 0 1-.32.634.78.78 0 0 1-.7.12l-.48-.148-1.876-.576a.78.78 0 0 1-.557-.754c0-.253.116-.484.32-.634a.78.78 0 0 1 .7-.12l1.594.49.762.234a.785.785 0 0 1 .557.754m-1.02 3.466-2.356-.724a.78.78 0 0 1-.557-.754c0-.253.116-.484.32-.634a.78.78 0 0 1 .7-.12l1.594.49.762.234a.785.785 0 0 1 .557.754.78.78 0 0 1-.32.634.78.78 0 0 1-.7.12m11.305 4.301H25.44c-.84 0-1.524-.683-1.524-1.523v-.203h10.816zm4.012-14.966a2.07 2.07 0 0 0-.406 1.234v4.249l-.245.17a7.8 7.8 0 0 0-3.361 6.415v.156H23.916v-.526c.29-.042.569-.154.814-.336.465-.344.732-.873.732-1.45 0-.536-.23-1.024-.613-1.358.391-.343.613-.828.613-1.355 0-.535-.23-1.024-.613-1.358.391-.342.613-.827.613-1.355 0-.535-.23-1.023-.613-1.357.391-.342.613-.828.613-1.355 0-.797-.512-1.49-1.274-1.724l-.272-.084V21.54c0-.84.683-1.523 1.523-1.523h11.782c.84 0 1.523.683 1.523 1.523v.203H27.09a.508.508 0 0 0 0 1.016h11.655z"></path>
      <path d="M35.044 28.337a5.15 5.15 0 0 0-2.744-1.435 5.17 5.17 0 0 0-3.001.354.508.508 0 0 0 .408.93 4.14 4.14 0 0 1 4.618.87 4.17 4.17 0 0 1 0 5.888 4.14 4.14 0 0 1-2.944 1.22 4.14 4.14 0 0 1-2.945-1.22 4.19 4.19 0 0 1-.861-4.637.508.508 0 0 0-.928-.412 5.17 5.17 0 0 0-.366 3.011 5.15 5.15 0 0 0 1.437 2.757 5.15 5.15 0 0 0 3.663 1.517 5.15 5.15 0 0 0 3.662-1.517 5.186 5.186 0 0 0 0-7.326"></path>
      <path d="M28.434 28.335a.508.508 0 0 0-.718.718l.003.003a.503.503 0 0 0 .716-.002.51.51 0 0 0-.001-.72M34.218 30.102a.51.51 0 0 0-.718 0l-2.265 2.264-1.23-1.23a.508.508 0 0 0-.719.718l1.59 1.59a.506.506 0 0 0 .718 0l2.624-2.624a.51.51 0 0 0 0-.718"></path>
    </g>
    <defs>
      <clipPath id="clip0_139_1807">
        <path fill="#fff" d="M18 19h26v26H18z"></path>
      </clipPath>
    </defs>
  </svg>
);

export default MobileIcon;
