const SvgIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    fill="none"
    viewBox="0 0 52 52"
  >
    <circle cx="26" cy="26" r="25" stroke="#242226" strokeWidth="2"></circle>
    <path
      fill="#FEFA17"
      fillRule="evenodd"
      d="M22.453 24.442a3.54 3.54 0 0 1 3.548-3.539 3.55 3.55 0 0 1 3.547 3.548 3.54 3.54 0 0 1-3.547 3.538 3.55 3.55 0 0 1-3.548-3.547m1.375.009c0 1.2.972 2.172 2.173 2.172 1.2 0 2.172-.971 2.172-2.172s-.98-2.173-2.172-2.173a2.177 2.177 0 0 0-2.173 2.173"
      clipRule="evenodd"
    ></path>
    <path
      fill="#FEFA17"
      fillRule="evenodd"
      d="M22.214 34.333c-2.704-2.604-5.693-6.756-4.565-11.697 1.017-4.482 4.932-6.49 8.35-6.49h.01c3.42 0 7.333 2.008 8.35 6.5 1.12 4.94-1.87 9.083-4.573 11.687A5.47 5.47 0 0 1 26 35.863a5.47 5.47 0 0 1-3.786-1.53m-3.218-11.394c-.99 4.317 1.724 8.039 4.18 10.395a4.057 4.057 0 0 0 5.656 0c2.448-2.356 5.161-6.078 4.19-10.396-.917-3.996-4.355-5.417-7.022-5.417-2.668 0-6.096 1.42-7.004 5.418"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgIcon;
