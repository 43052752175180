import React, { useEffect, useState } from 'react';
import ArrowButton from '@/assets/ArrowButton';
import ArticleImg from '../assets/articleImage.png';
import Michal from '../assets/juzek.jpg';
import ArticleCard from './ArticleCard';
import axios from "@/axiosConfig";

import christmas from '../assets/blog/christmas/Miniaturka.png';
import vignette from '../assets/blog/evignette/image-1.png';
import austria from '../assets/blog/austria/image-1.png';
import { useTranslation } from 'react-i18next';

interface Article {
    id: number;
    title: string;
    mainImageUrl: string;
    slug: string;
    createdDate: Date;
  }
  
  // const initialArticles: Article[] = [
  //   {
  //     id: 1,
  //     title: "Top 3 Jarmarki Bożonarodzeniowe w Europie 2024",
  //     createdDate: new Date("26.11.2024"),
  //     mainImageUrl: christmas.src,
  //   }
  // ];

const BlogSection: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const { t } = useTranslation('common');

  useEffect(() => {
    const fetchArticles = async () => {
      try {
          //const token = getUserToken();
          //"Bearer ${token}"
          const response = await axios.get('/Post?pageSize=3');
          setArticles(response.data.data ? response.data.data : []);
      } 
      catch (error) {
        console.log("Błąd podczas pobierania danych:", error);
      }
    };

    fetchArticles();
  }, []);

  return (
    <section className="py-12 lg:max-w-[1200px] p-6 lg:p-0 mx-auto">
      <div className="lg:flex lg:justify-between lg:items-center mb-6 lg:space-x-12 space-y-10 lg:space-y-0">
        <div className="flex flex-col">
            <h2 className="text-[45px] font-[700] leading-[61px] text-base-100 mb-0">{t('blogSection.header1')}</h2>
            <p className="text-base-100 text-[16px] leading-[35px]">{t('blogSection.text1')}</p>
        </div>
        <div className="lg:w-1/2">
            <div className='flex justify-end'>
                <a
                    href="/blog"
                    className="no-underline items-center text-sm lg:text-base font-bold bg-accent text-black px-6 py-3 border-0 border-solid border-base-100 rounded-full hover:bg-yellow-600 hover:border-yellow-600 transition"
                >
                    {t('blogSection.button')}
                </a>
            </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mt-12">
        {
        articles.length > 0 ?
          articles.map((article, index) => (
              <a href={`/article/${article.slug}`} key={index} className='no-underline'>
                <ArticleCard
                    image={"https://api.winiety24.eu/"+article.mainImageUrl}
                    date={new Date(article.createdDate).toLocaleDateString()}
                    title={article.title}
                    authorName="Juzek"
                    authorLocation="Kraków, Poland"
                    authorAvatar={Michal.src}
                />
              </a>
          ))
        :
        <>
          <p className='text-base-100'>  
            Brak ostatnich artykułów
          </p>
        </>
      }
      </div>
    </section>
  );
};

export default BlogSection;
